import React from "react"
import Layout from "../../components/Layout"

import AilmentResults from "../../components/SymptomChecker/AilmentResults"
import ProtectedPage from "../../components/common/ProtectedPage"

const AilmentResultsPage = () => {
  return (
    <Layout seoProps={{title: "Ailment Results"}}>
      <ProtectedPage>
        <AilmentResults />
      </ProtectedPage>

    </Layout>
  )
}



export default AilmentResultsPage